//引入重定向
import { Navigate } from 'react-router-dom';
//懒加载，suspense防止跳转页面闪屏

import { lazy, Suspense } from 'react';




// 一级路由
let Index = lazy(() => import('../views'));
// 二级路由
let Home = lazy(() => import('../views/Home/Home'));
let Product = lazy(() => import('../views/Product/Product'));
let Solution = lazy(() => import('../views/Solution/Solution'));
let Forwe = lazy(() => import('../views/Forwe/Forwe'));
let Connect = lazy(() => import('../views/Connect/Connect'));
let Information = lazy(() => import('../views/Information/Information'));


let Detail = lazy(() => import('../views/Product/Detail'))
let Wisdom = lazy(() => import('../views/Solution/Wisdom'))
let Context = lazy(() => import('../views/Information/Context'));

let News = lazy(() => import('../views/News/News'));


let Sz = lazy(()=>import('../views/Connect/Sz'))
let Xz = lazy(()=>import('../views/Connect/Xz'))

const routes = [
    {
        path: '',
        element: <Index />  ,
        children: [
            {
                path: "/home",
                element: <Home />
            },
            {
                path: "/product",
                element: <Product />,
                children:[
                    {
                        path:"/product/detail/:id",
                        element:<Detail />
                    }
                ]
            },

            {
                path: "/solution",
                element: <Solution />,
                children:[
                    {
                    path:"/solution/wisdom/:id",
                    element:<Wisdom />
                }
            ]

            },
            {
                path: "/forwe",
                element: <Forwe />
            },
            {
                path: "/connect",
                element: <Connect />
            },
            {
                path: "/news/:type/:id",
                element: <News />
            },
            {
                path: "/information",
                element: <Information />,
                children:[
                    {
                        path:"/information/context/:id",
                        element:<Context />,
                        children:[
                            {
                                path:"/information/context/:id/news/:id",
                                element:<News />
                            }
                        ]
                    },
                    {
                        path:"/information",
                        element:<Navigate to='/information/context/1' />
                    }
                ]
            },
            {

                path:"/connext/sz",
                element:<Sz />
            },
            {

                path:"/connext/xz",
                element:<Xz />
            }
            ,{
                path:"",
                element: <Navigate to='/home' />
            }
        ]
    },
    {
        path: '/',
        element: <Navigate to='/home'/>
    }

]
export default routes;
