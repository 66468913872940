import React, { Component, Suspense, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './router';
import './App.css';

function App() {
  return (
    <div className="App">
    <Suspense  >
          {useRoutes(routes)}
    </Suspense>
    </div>
  );
}
export default App;
